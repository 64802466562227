import { type LoaderFunctionArgs, json } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { type ISbStories } from '@storyblok/react'
import { parse as parseCookie } from 'cookie'
import AnnouncementBanner from '#app/components/announcement-banner'
import { FooterReturn } from '#app/components/footer-return.tsx'
import Navbar from '#app/components/navigation/navbar'
import { getUserId } from '#app/server/auth.server.ts'
import { isPreview } from '#app/utils/isPreview'
import { languageSession } from '#app/utils/localisation/lang.server'
import {
	getLanguageFromCookieOrBrowserOrFallback,
	isAcceptedLanguage,
} from '#app/utils/localisation/lokalisation-utilities'
import { fetchStories } from '#app/utils/server/storyblok-server-fetch'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'

export async function loader({ request }: LoaderFunctionArgs) {
	const langSession = await languageSession.getSession(
		request.headers.get('cookie'),
	)
	const version = isPreview() ? 'draft' : 'published'

	let language = langSession.get('sessionLanguage')
	language = getLanguageFromCookieOrBrowserOrFallback(language, request)
	const userId = await getUserId(request)

	const navigationStoryblok: ISbStories = await fetchStories({
		starts_with: 'navigation',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: version,
	})

	const cookieHeader = request.headers.get('Cookie')
	const cookies = parseCookie(cookieHeader || '')
	const bannerVisible = cookies.bannerVisible === 'false' ? false : true
	const url = new URL(request.url)
	const pathSegments = url.pathname.split('/')

	// Extract category key from the URL

	// Extract category key from the URL
	let categoryKey = 'kids' // Default value
	const possibleCategories = ['kids', 'women', 'men']
	for (const segment of pathSegments) {
		const cleanSegment = segment.split('-')[0].toLowerCase() // Remove suffixes like '-landing'
		if (possibleCategories.includes(cleanSegment)) {
			categoryKey = cleanSegment
			break
		}
	}

	const navigation: NavigationCategoriesStoryblok[] =
		navigationStoryblok.data.stories.map((story: any) => {
			return story.content
		})

	let announcementData = await fetchStories({
		starts_with: 'announcement-banner',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: version,
	})
	let searchData = await fetchStories({
		starts_with: 'search',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: version,
	})

	const announcementContent =
		announcementData.data.stories.length > 0
			? announcementData.data.stories[0].content
			: null
	return json({
		userId,
		searchData,
		navigation,
		language,
		announcement: announcementContent,
		bannerVisible,
		categoryKey, // Include categoryKey in the response
	})
}

export default function AuthLayout() {
	const { userId, navigation, language, bannerVisible, announcement } =
		useLoaderData<typeof loader>()

	return (
		<div className="flex min-h-screen flex-col bg-beige-50-opacity bg-ampersand bg-cover bg-center bg-no-repeat">
			<div>
				<AnnouncementBanner
					announcement={announcement}
					bannerVisible={bannerVisible}
				/>
				<Navbar
					userId={userId}
					navigationData={navigation}
					sessionLanguage={language}
				/>
			</div>
			<div id="bag-item-portal" />
			<div className="flex flex-grow items-center justify-center px-4 py-12">
				<div className="mx-auto w-full max-w-xl">
					<Outlet />
				</div>
			</div>
			<FooterReturn />
		</div>
	)
}
